import { SAVE_EXACT_ORDERS } from "../constants/Exact";

const initState = {
  orders: [],
};

const exact = (state = initState, action) => {
  switch (action.type) {
    case SAVE_EXACT_ORDERS:
      const newOrders = action.payload;
      return {
        ...state,
        orders: newOrders,
      };
    default:
      return state;
  }
};

export default exact;
